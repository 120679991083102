define("discourse/plugins/discourse-global-communities/discourse/connectors/below-footer/agc-footer-outlet", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse/plugins/discourse-global-communities/discourse/components/agc/custom-footer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _customFooter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcFooterOutlet extends _component.default {
    static shouldRender(_1, context1) {
      return context1.siteSettings.enable_agc_navs;
    }
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get forceShow() {
      return this.router.currentRouteName === "program-pillars";
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (or this.forceShow @outletArgs.showFooter)}}
          <AgcCustomFooter />
        {{/if}}
      
    */
    {
      "id": "6zpH1MNF",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"forceShow\"]],[30,1,[\"showFooter\"]]],null],[[[1,\"      \"],[8,[32,1],null,null,null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/below-footer/agc-footer-outlet.js",
      "scope": () => [_truthHelpers.or, _customFooter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcFooterOutlet;
});
define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/search-menu", ["exports", "discourse/components/search-menu", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _searchMenu, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchMenuCustomizations {
    static apply(api) {
      const currentUser = api.getCurrentUser();
      if (!currentUser) {
        return;
      }
      api.headerIcons.delete("search");
      const site = api.container.lookup("service:site");
      if (site.desktopView) {
        api.renderInOutlet("before-header-panel", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                {{#unless @outletArgs.topicInfoVisible}}
                  <div class="search-menu">
                    <SearchMenu />
                  </div>
                {{/unless}}
              
        */
        {
          "id": "AdrRgnkO",
          "block": "[[[1,\"\\n\"],[41,[51,[30,1,[\"topicInfoVisible\"]]],[[[1,\"          \"],[10,0],[14,0,\"search-menu\"],[12],[1,\"\\n            \"],[8,[32,0],null,null,null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[\"@outletArgs\"],false,[\"unless\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/search-menu.js",
          "scope": () => [_searchMenu.default],
          "isStrictMode": true
        }), (0, _templateOnly.default)()));
      } else {
        api.headerButtons.add("agc-search-field", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <div class="search-menu"><SearchMenu /></div>
              
        */
        {
          "id": "aTBErPV6",
          "block": "[[[1,\"\\n        \"],[10,0],[14,0,\"search-menu\"],[12],[8,[32,0],null,null,null],[13],[1,\"\\n      \"]],[],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/search-menu.js",
          "scope": () => [_searchMenu.default],
          "isStrictMode": true
        }), (0, _templateOnly.default)()));
      }
    }
  }
  _exports.default = SearchMenuCustomizations;
});
define("discourse/plugins/discourse-global-communities/discourse/initializers/feature-flags", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-comment-tagging", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-related-suggested-topics", "discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-related-topics", "discourse/plugins/discourse-global-communities/discourse/feature-flags/enhanced-notifications", "discourse/plugins/discourse-global-communities/discourse/feature-flags/hide-user-summary", "discourse/plugins/discourse-global-communities/discourse/feature-flags/home-feed-user-option-default-sorting", "discourse/plugins/discourse-global-communities/discourse/feature-flags/mobile-tab-bar", "discourse/plugins/discourse-global-communities/discourse/feature-flags/program-pillars", "discourse/plugins/discourse-global-communities/discourse/feature-flags/topic-show-more", "discourse/plugins/discourse-global-communities/discourse/feature-flags/wywa-loading-indicator"], function (_exports, _pluginApi, _agcCommentTagging, _agcNavs, _agcRelatedSuggestedTopics, _agcRelatedTopics, _enhancedNotifications, _hideUserSummary, _homeFeedUserOptionDefaultSorting, _mobileTabBar, _programPillars, _topicShowMore, _wywaLoadingIndicator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const featureFlags = [_agcCommentTagging.default, _mobileTabBar.default, _agcNavs.default, _agcRelatedTopics.default, _agcRelatedSuggestedTopics.default, _enhancedNotifications.default, _hideUserSummary.default, _homeFeedUserOptionDefaultSorting.default, _programPillars.default, _topicShowMore.default, _wywaLoadingIndicator.default];
  var _default = _exports.default = {
    name: "agc-base-feature-flags",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      (0, _pluginApi.withPluginApi)("1.35.0", api => {
        const currentUser = api.getCurrentUser();
        if (currentUser) {
          currentUser.agcFeatureFlags = {};
        }
        featureFlags.forEach(featureFlag => {
          let enableFeatureFlag = false;
          if (siteSettings[featureFlag.enabledSetting]) {
            // Global enabled setting is true
            enableFeatureFlag = true;
          } else if (featureFlag.groupsSetting && siteSettings[featureFlag.groupsSetting]) {
            if (!currentUser) {
              return;
            }

            // There is a group/multiple group IDS in the enable for group setting.
            // Check current user's group IDs for intersection
            const currentUserGroupIds = currentUser.groups.map(g => g.id);
            const groupIds = siteSettings[featureFlag.groupsSetting].split("|").map(g => parseInt(g, 10));
            if (currentUserGroupIds.some(id => groupIds.includes(id))) {
              enableFeatureFlag = true;
            }
          }
          if (enableFeatureFlag) {
            if (featureFlag.className) {
              document.body.classList.add(featureFlag.className);
            }
            if (currentUser) {
              currentUser.agcFeatureFlags[featureFlag.enabledSetting] = true;
            }
            featureFlag?.afterEnabled?.(api);
          }
        });
      });
    }
  };
});
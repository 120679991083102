define("discourse/plugins/discourse-global-communities/discourse/components/gc-location-picker", ["exports", "@ember/component", "@ember/object", "jquery", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _component, _object, _jquery, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: ["isExpanded"],
    classNames: ["location-picker"],
    locationsObjects: null,
    isExpanded: false,
    headerText: _discourseI18n.default.t("discourse_gc.location_picker.all"),
    searchTerm: null,
    _setupComponent() {
      this.set("locationsObjects", []);
      this.locations.forEach(loc => {
        this.locationsObjects.pushObject(_object.default.create(loc));
      });
      this.outsideClickEvent = (() => {
        if (!this.element.contains(event.target)) {
          this.collapse();
        }
      }).bind(this);

      // if we have pre-checked some items we want the header text to
      // match
      const appliedLocations = this.locationsObjects.filter(loc => loc.checked);
      if (appliedLocations) {
        this.updateHeaderText(appliedLocations);
      }
    },
    filteredLocations(term) {
      const locations = this.locationsObjects;
      if (!term || term.length === 0) {
        return locations;
      }
      return this.locationsObjects.filter(loc => loc.name.toLowerCase().indexOf(term.toLowerCase()) !== -1);
    },
    updateHeaderText(locations) {
      if (locations.length > 0) {
        this.set("headerText", locations.map(loc => loc.name).join(", "));
      } else {
        this.set("headerText", _discourseI18n.default.t("discourse_gc.location_picker.all"));
      }
    },
    _setupEvents() {
      (0, _jquery.default)(window).on("mouseup touchstart", this.outsideClickEvent);
    },
    _destroyEvents() {
      (0, _jquery.default)(window).off("mouseup touchstart", this.outsideClickEvent);
    },
    expand() {
      this.set("isExpanded", true);
      this._positionBody();
    },
    collapse() {
      this.set("isExpanded", false);
    },
    click(e) {
      if (!this.isExpanded) {
        this.expand();
      } else {
        const body = this.element.querySelector(".location-picker-body");
        if (!body.contains(e.target)) {
          this.collapse();
        }
      }
    },
    _positionBody() {
      const height = this.element.offsetHeight;
      this.element.querySelector(".location-picker-body").style.top = `${height}px`;
    },
    actions: {
      apply() {
        const appliedLocations = this.locationsObjects.filter(loc => loc.checked);
        this.applyFilters(appliedLocations);
        this.updateHeaderText(appliedLocations);
        this.collapse();
      },
      checkLocation(loc) {
        loc.toggleProperty("checked");
      },
      clear() {
        this.locationsObjects.forEach(loc => loc.set("checked", false));
        this.applyFilters([]);
        this.updateHeaderText([]);
        this.set("searchTerm", null);
        this.collapse();
      }
    }
  }, [["method", "_setupComponent", [(0, _decorators.on)("init")]], ["method", "filteredLocations", [(0, _decorators.default)("searchTerm")]], ["method", "_setupEvents", [(0, _decorators.on)("didInsertElement")]], ["method", "_destroyEvents", [(0, _decorators.on)("didDestroyElement")]]]));
});
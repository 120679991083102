define("discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/sidebar", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-global-communities/discourse/components/agc-sidebar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _agcSidebar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcDiscoverySidebar extends _component.default {
    static shouldRender(args1, context1) {
      return context1.siteSettings.enable_agc_sidebar;
    }
    static #_ = (() => dt7948.g(this.prototype, "agcDiscovery", [_service.service]))();
    #agcDiscovery = (() => (dt7948.i(this, "agcDiscovery"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable modifier-name-case }}
        <AgcSidebar
          @gcShareYourExpertise={{this.agcDiscovery.gcShareYourExpertise}}
          @gcFeatured={{this.agcDiscovery.gcFeatured}}
          @gcTopContributors={{this.agcDiscovery.gcTopContributors}}
          @isHomepage={{this.agcDiscovery.isHomepage}}
          @isDiscoveryCategoryRoute={{this.agcDiscovery.isDiscoveryCategoryRoute}}
          @category={{@outletArgs.category}}
        />
      
    */
    {
      "id": "aXFMXG0W",
      "block": "[[[1,\"\\n\"],[1,\"    \"],[8,[32,0],null,[[\"@gcShareYourExpertise\",\"@gcFeatured\",\"@gcTopContributors\",\"@isHomepage\",\"@isDiscoveryCategoryRoute\",\"@category\"],[[30,0,[\"agcDiscovery\",\"gcShareYourExpertise\"]],[30,0,[\"agcDiscovery\",\"gcFeatured\"]],[30,0,[\"agcDiscovery\",\"gcTopContributors\"]],[30,0,[\"agcDiscovery\",\"isHomepage\"]],[30,0,[\"agcDiscovery\",\"isDiscoveryCategoryRoute\"]],[30,1,[\"category\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/sidebar.js",
      "scope": () => [_agcSidebar.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcDiscoverySidebar;
});
define("discourse/plugins/discourse-global-communities/discourse/components/gc-sidebar-featured", ["exports", "@ember/helper", "@ember/template", "discourse/helpers/replace-emoji", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _template2, _replaceEmoji, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @gcFeatured.length}}
      <div class="homepage-hero-quaternary homepage-hero-cell">
        <h3 class="cell-title">{{i18n
            "agc.homepage.hero.quaternary.headline"
          }}</h3>
        <section>
          {{#each @gcFeatured as |fh|}}
            <a
              href={{fh.url}}
              data-agc-analytics-ref="nav_sidebar_featured_heading"
            >
              {{#if fh.thumbnail_url}}
                <div
                  class="hero-img"
                  style={{htmlSafe
                    (concat "background: url(" fh.thumbnail_url ")")
                  }}
                ></div>
              {{/if}}
              <h4>{{htmlSafe fh.fancy_title}}</h4>
              <p>{{replaceEmoji (htmlSafe fh.excerpt)}}</p>
            </a>
          {{/each}}
        </section>
      </div>
    {{/if}}
  
  */
  {
    "id": "DXZ+hVm6",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"homepage-hero-quaternary homepage-hero-cell\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"cell-title\"],[12],[1,[28,[32,0],[\"agc.homepage.hero.quaternary.headline\"],null]],[13],[1,\"\\n      \"],[10,\"section\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"          \"],[10,3],[15,6,[30,2,[\"url\"]]],[14,\"data-agc-analytics-ref\",\"nav_sidebar_featured_heading\"],[12],[1,\"\\n\"],[41,[30,2,[\"thumbnail_url\"]],[[[1,\"              \"],[10,0],[14,0,\"hero-img\"],[15,5,[28,[32,1],[[28,[32,2],[\"background: url(\",[30,2,[\"thumbnail_url\"]],\")\"],null]],null]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[10,\"h4\"],[12],[1,[28,[32,1],[[30,2,[\"fancy_title\"]]],null]],[13],[1,\"\\n            \"],[10,2],[12],[1,[28,[32,3],[[28,[32,1],[[30,2,[\"excerpt\"]]],null]],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@gcFeatured\",\"fh\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/gc-sidebar-featured.js",
    "scope": () => [_i18n.default, _template2.htmlSafe, _helper.concat, _replaceEmoji.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});
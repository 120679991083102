define("discourse/plugins/discourse-global-communities/discourse/components/gc-member", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _component, _computed, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["member"],
    displayName: (0, _computed.or)("member.name", "member.username"),
    domicileOrCity: (0, _computed.or)("member.fields.domicile", "member.fields.city_state"),
    domicileAndCity: (0, _computed.and)("member.fields.domicile", "member.fields.city_state"),
    agcAnalyticsEventData(username) {
      return `{"username":"${username}"}`;
    },
    joinDate(date) {
      return moment(date).format("MMMM, YYYY");
    },
    messageButtonHelp(name, noPms) {
      if (noPms) {
        return _discourseI18n.default.interpolate(this.notAcceptingPms, {
          username: name
        });
      }
    },
    memberLocation(fields) {
      const {
        station,
        station_location
      } = fields;
      let result = [];
      if (station && station.length) {
        result.push(station);
      }
      if (station_location && station_location.length) {
        result.push(station_location);
      }
      return result.join(" / ");
    },
    memberLink(username) {
      if (this.siteSettings.enable_user_cards) {
        return `/u/${username}`;
      } else {
        return `/u/${username}/summary`;
      }
    },
    actions: {
      message() {
        this.message(this.member.username);
      }
    }
  }, [["method", "agcAnalyticsEventData", [(0, _decorators.default)("member.username")]], ["method", "joinDate", [(0, _decorators.default)("member.created_at")]], ["method", "messageButtonHelp", [(0, _decorators.default)("displayName", "member.no_pms")]], ["method", "memberLocation", [(0, _decorators.default)("member.fields")]], ["method", "memberLink", [(0, _decorators.default)("member.username")]]]));
});
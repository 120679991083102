define("discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/topic-tile", ["exports", "@glimmer/component", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/topic-list-item", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/lib/analytics-annotator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _didInsert, _service, _topicListItem, _decorators, _discourseI18n, _analyticsAnnotator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicTile extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    get tileLabel() {
      if (!this.args.topic?.tileLabel) {
        return;
      }
      return _discourseI18n.default.t(this.args.topic.tileLabel);
    }
    registerAnalyticsTracker(element1) {
      element1.addEventListener("click", event1 => {
        if (event1.target.closest(".meta-likes.can-like")) {
          const firstPostLiked1 = !this.args.topic?.topic_first_post_liked;
          if (firstPostLiked1) {
            (0, _analyticsAnnotator.triggerCarouselAnalytics)("wywa_carousel_topic_liked", this.appEvents, {
              topic_id: this.args.topic.id
            });
          }
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "registerAnalyticsTracker", [_decorators.bind]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="tile topic-tile"
          ...attributes
          {{didInsert this.registerAnalyticsTracker}}
        >
          <div class="tile-label">
            {{this.tileLabel}}
          </div>
          <TopicListItem @topic={{@topic}} />
        </div>
      
    */
    {
      "id": "1L+eMHIQ",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"tile topic-tile\"],[17,1],[4,[32,0],[[30,0,[\"registerAnalyticsTracker\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"tile-label\"],[12],[1,\"\\n        \"],[1,[30,0,[\"tileLabel\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[32,1],null,[[\"@topic\"],[[30,2]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@topic\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/carousel/topic-tile.js",
      "scope": () => [_didInsert.default, _topicListItem.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicTile;
});
define("discourse/plugins/discourse-global-communities/discourse/controllers/program-pillars", ["exports", "@ember/controller", "@ember/service", "discourse-i18n"], function (_exports, _controller, _service, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PROGRAM_PILLARS_ROUTE_NAME = "program-pillars";
  class ProgramPillarsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on("agc-custom:filter-select", this, this._handleFilterSelect);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("agc-custom:filter-select", this, this._handleFilterSelect);
    }
    get topWidgetTitle() {
      const count = this.model.programPillar.top_widget_topics.length;
      if (this.t) {
        let tag = this.childTagForTagName(this.t)?.translation_key ? _discourseI18n.default.t(this.childTagForTagName(this.t).translation_key) : this.t;
        const topWigdetTagTitleKey = this.model.programPillar?.top_widget_filter_tag_title_key || "top_widget_title_tag";
        return _discourseI18n.default.t(topWigdetTagTitleKey, {
          tag,
          count
        });
      } else {
        const topWidgetAllTitleKey = this.model.programPillar?.top_widget_all_title_key || "program_pillars.top_widget_title_all";
        return _discourseI18n.default.t(topWidgetAllTitleKey, {
          count
        });
      }
    }
    get bottomWidgetTitle() {
      const bottomWidgetTitleKey = this.model.programPillar?.bottom_widget_title_key || "program_pillars.bottom_widget_title";
      return _discourseI18n.default.t(bottomWidgetTitleKey, {
        count: this.model.programPillar.bottom_widget_topics.length
      });
    }
    childTagForTagName(tagName) {
      return this.model.programPillar.child_tags.find(ct => ct.tag_name === tagName);
    }
    get tagOptions() {
      const allOption = {
        id: "all",
        label: _discourseI18n.default.t("program_pillars.all_option_label"),
        count: this.model.programPillar.total_count
      };
      const childTags = this.model.programPillar.child_tags.map(t => {
        return {
          id: t.tag_name,
          label: t.translation_key ? _discourseI18n.default.t(t.translation_key) : t.tag_name,
          count: t.count
        };
      });
      return [allOption, ...childTags];
    }
    get defaultTagOption() {
      return this.tagOptions[0].id;
    }
    _handleFilterSelect(_ref) {
      let {
        scope,
        selected
      } = _ref;
      if (scope !== PROGRAM_PILLARS_ROUTE_NAME) {
        return;
      }
      selected === this.defaultTagOption || selected === this.get("t") ? this.set("t", "") : this.set("t", selected);
    }
    get hasHero() {
      return !!this.model.programPillar.hero_upload;
    }
    get hasWidgets() {
      return this.model.programPillar.top_widget_topics.length || this.model.programPillar.bottom_widget_topics.length;
    }
    get widgets() {
      const widgets = [];
      if (this.model.programPillar.top_widget_topics.length) {
        const [topWidgetTopics, topWidgetAdditionalTopics] = this.#splitWidgetTopics(this.model.programPillar.top_widget_topics);
        widgets.push({
          title: this.topWidgetTitle,
          topics: topWidgetTopics,
          additionalTopics: topWidgetAdditionalTopics,
          class: "program-pillars-top-widget"
        });
      }
      if (this.model.programPillar.bottom_widget_topics.length) {
        const [bottomWidgetTopics, bottomWidgetAdditionalTopics] = this.#splitWidgetTopics(this.model.programPillar.bottom_widget_topics);
        widgets.push({
          title: this.bottomWidgetTitle,
          topics: bottomWidgetTopics,
          additionalTopics: bottomWidgetAdditionalTopics,
          class: "program-pillars-bottom-widget"
        });
      }
      return widgets;
    }
    #splitWidgetTopics(topics) {
      if (this.site.mobileView) {
        return [topics, null];
      }
      return [topics.slice(0, 5), topics.slice(5)];
    }
  }
  _exports.default = ProgramPillarsController;
});
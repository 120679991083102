define("discourse/plugins/discourse-global-communities/discourse/components/theme-enable-warning", ["exports", "discourse-common/helpers/i18n", "truth-helpers/helpers/and", "truth-helpers/helpers/not", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _i18n, _and, _not, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ThemeEnableWarning = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if (and @theme.component (not (and @theme.enabled @onlyShowIfDisabled)))}}
      <div class="alert alert-danger">
        <h2>{{i18n "admin.theme_enable_warning"}}</h2>
      </div>
    {{/if}}
  
  */
  {
    "id": "JvNCsWCu",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"component\"]],[28,[32,1],[[28,[32,0],[[30,1,[\"enabled\"]],[30,2]],null]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,[28,[32,2],[\"admin.theme_enable_warning\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@theme\",\"@onlyShowIfDisabled\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/theme-enable-warning.js",
    "scope": () => [_and.default, _not.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ThemeEnableWarning;
});
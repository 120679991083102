define("discourse/plugins/discourse-global-communities/discourse/connectors/topic-more-content/agc-related-topics", ["exports", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcRelatedTopics extends _component.default {
    static shouldRender(_, context) {
      const isFeatureEnabled = context.currentUser?.agcFeatureFlags?.agc_related_suggested_topics_enabled;
      return isFeatureEnabled;
    }
    static #_ = (() => dt7948.g(this.prototype, "moreTopicsPreferenceTracking", [_service.service]))();
    #moreTopicsPreferenceTracking = (() => (dt7948.i(this, "moreTopicsPreferenceTracking"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    suggestedTopicsListId = "suggested-topics";
    relatedTopicsListId = "related-topics";
    removeSuggestedOrRelatedTopics() {
      const isUserAllowedToSeeRelatedTopics = this.currentUser.agcFeatureFlags?.agc_related_topics_enabled;
      if (isUserAllowedToSeeRelatedTopics && this.moreTopicsPreferenceTracking.topicLists.map(item => item.id).includes(this.suggestedTopicsListId)) {
        this.moreTopicsPreferenceTracking.removeTopicList(this.suggestedTopicsListId);
      } else if (!isUserAllowedToSeeRelatedTopics && this.moreTopicsPreferenceTracking.topicLists.map(item => item.id).includes(this.relatedTopicsListId)) {
        this.moreTopicsPreferenceTracking.removeTopicList(this.relatedTopicsListId);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "removeSuggestedOrRelatedTopics", [_object.action]))();
  }
  _exports.default = AgcRelatedTopics;
});
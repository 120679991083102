define("discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/custom-category-heading", ["exports", "@glimmer/component", "@ember/object", "@ember/owner", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "discourse/components/d-button", "discourse/lib/url", "discourse/mixins/scroll-top", "discourse/models/category", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _owner, _didInsert, _willDestroy, _service, _dButton, _url, _scrollTop, _category, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcCustomCategoryHeading extends _component.default {
    static shouldRender(args1, context1) {
      const router1 = (0, _owner.getOwner)(context1).lookup("service:router");
      const agcDiscovery1 = (0, _owner.getOwner)(context1).lookup("service:agc-discovery");
      const routeName1 = router1.currentRouteName;
      return routeName1.startsWith("discovery.") && !["discovery.categories"].includes(routeName1) && !agcDiscovery1.isHomepage;
    }
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get canEditCategory() {
      return this.currentUser.admin;
    }
    get currentCategory() {
      return this.discovery.category;
    }
    addHtmlClass() {
      const html1 = document.querySelector("html");
      html1.classList.add("show-custom-category");
      (0, _scrollTop.scrollTop)();
    }
    removeHtmlClass() {
      const html1 = document.querySelector("html");
      html1.classList.remove("show-custom-category");
    }
    editCategory() {
      _url.default.routeTo(`/c/${_category.default.slugFor(this.currentCategory)}/edit`);
    }
    static #_4 = (() => dt7948.n(this.prototype, "editCategory", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable modifier-name-case }}
        <div
          class="custom-category-wrapper"
          {{didInsert this.addHtmlClass}}
          {{willDestroy this.removeHtmlClass}}
        >
          <h1>
            <span class="category-name">
              <span>{{i18n "agc.custom-category.headline"}}</span>
              {{#if this.currentCategory.name}}
                {{this.currentCategory.name}}
              {{else}}
                {{i18n "agc.all_topics"}}
              {{/if}}
            </span>
    
            {{#if this.currentCategory.name}}
              {{#if this.canEditCategory}}
                <DButton
                  class="btn-default edit-category"
                  @action={{this.editCategory}}
                  @icon="wrench"
                  @label="category.edit"
                />
              {{/if}}
            {{/if}}
          </h1>
        </div>
      
    */
    {
      "id": "mIraVerG",
      "block": "[[[1,\"\\n\"],[1,\"    \"],[11,0],[24,0,\"custom-category-wrapper\"],[4,[32,0],[[30,0,[\"addHtmlClass\"]]],null],[4,[32,1],[[30,0,[\"removeHtmlClass\"]]],null],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"category-name\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[32,2],[\"agc.custom-category.headline\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"currentCategory\",\"name\"]],[[[1,\"            \"],[1,[30,0,[\"currentCategory\",\"name\"]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[32,2],[\"agc.all_topics\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"currentCategory\",\"name\"]],[[[41,[30,0,[\"canEditCategory\"]],[[[1,\"            \"],[8,[32,3],[[24,0,\"btn-default edit-category\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"editCategory\"]],\"wrench\",\"category.edit\"]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/custom-category-heading.js",
      "scope": () => [_didInsert.default, _willDestroy.default, _i18n.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcCustomCategoryHeading;
});
define("discourse/plugins/discourse-global-communities/discourse/components/gc-location-picker-v2", ["exports", "@ember/component", "@ember/object", "jquery", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _component, _object, _jquery, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: ["isExpanded"],
    classNames: ["location-picker"],
    filterGroupsObjects: null,
    isExpanded: false,
    headerText: _discourseI18n.default.t("discourse_gc.location_picker.all"),
    searchTerm: null,
    _setupComponent() {
      this.set("filterGroupsObjects", []);
      this.filterGroups.forEach(filterGroup => {
        const filters = filterGroup.filters.map(value => _object.default.create({
          value,
          type: filterGroup.type,
          checked: this.selectedFilters[filterGroup.type.toLowerCase()] === value
        }));
        this.filterGroupsObjects.pushObject(_object.default.create({
          label: filterGroup.label,
          isExpanded: false,
          filters
        }));
      });
      this.outsideClickEvent = (() => {
        if (!this.element.contains(event.target)) {
          this.collapse();
        }
      }).bind(this);
      const appliedFilters = this.allFilterOptions.filter(filter => filter.checked);
      if (appliedFilters) {
        this.updateHeaderText(appliedFilters);
      }
    },
    allFilterOptions() {
      return this.filterGroupsObjects.map(filterGroupObject => filterGroupObject.filters).flat();
    },
    filteredLocations(term) {
      if (!term || term.length === 0) {
        return [];
      }
      return this.allFilterOptions.filter(option => option.value.toLowerCase().indexOf(term.toLowerCase()) !== -1);
    },
    displayGroupedFilterOptions(term) {
      return !term || term.length === 0;
    },
    updateHeaderText(filters) {
      if (filters.length > 0) {
        this.set("headerText", filters.map(loc => loc.value).join(", "));
      } else {
        this.set("headerText", _discourseI18n.default.t("discourse_gc.location_picker.all"));
      }
    },
    _setupEvents() {
      (0, _jquery.default)(window).on("mouseup touchstart", this.outsideClickEvent);
    },
    _destroyEvents() {
      (0, _jquery.default)(window).off("mouseup touchstart", this.outsideClickEvent);
    },
    expand() {
      this.set("isExpanded", true);
      this._positionBody();
    },
    collapse() {
      this.set("isExpanded", false);
      this.filterGroupsObjects.forEach(filterGroup => filterGroup.set("isExpanded", false));
    },
    click(e) {
      if (!this.isExpanded) {
        this.expand();
      } else {
        const body = this.element.querySelector(".location-picker-body");
        if (!body.contains(e.target)) {
          this.collapse();
        }
      }
    },
    _positionBody() {
      const height = this.element.offsetHeight;
      this.element.querySelector(".location-picker-body").style.top = `${height}px`;
    },
    actions: {
      apply() {
        const appliedFilters = this.allFilterOptions.filter(filter => filter.checked);
        this.applyFilters(appliedFilters);
        this.updateHeaderText(appliedFilters);
        this.collapse();
      },
      checkFilter(filter) {
        filter.toggleProperty("checked");
      },
      toggleGroup(group) {
        group.toggleProperty("isExpanded");
      },
      clear() {
        this.allFilterOptions.forEach(loc => loc.set("checked", false));
        this.applyFilters([]);
        this.updateHeaderText([]);
        this.set("searchTerm", null);
        this.collapse();
      }
    }
  }, [["method", "_setupComponent", [(0, _decorators.on)("init")]], ["method", "allFilterOptions", [(0, _decorators.default)()]], ["method", "filteredLocations", [(0, _decorators.default)("searchTerm")]], ["method", "displayGroupedFilterOptions", [(0, _decorators.default)("searchTerm")]], ["method", "_setupEvents", [(0, _decorators.on)("didInsertElement")]], ["method", "_destroyEvents", [(0, _decorators.on)("didDestroyElement")]]]));
});
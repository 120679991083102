define("discourse/plugins/discourse-global-communities/discourse/connectors/menu-item-end/agc-notifications-secondary-dropdown", ["exports", "@ember/owner"], function (_exports, _owner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(outletArgs, container) {
      const router = (0, _owner.getOwner)(container).lookup("service:router");
      const isNotificationPage = router.currentRoute.name === "userNotifications.index";
      const isFeatureEnabled = container.currentUser.agcFeatureFlags["enhanced_notifications_enabled"];
      return isFeatureEnabled && isNotificationPage;
    }
  };
});
define("discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/custom-tag-heading", ["exports", "@glimmer/component", "@ember/controller", "@ember/helper", "@ember/object", "@ember/owner", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "discourse/components/d-button", "discourse/mixins/scroll-top", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _controller, _helper, _object, _owner, _didInsert, _willDestroy, _service, _dButton, _scrollTop, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcCustomTagHeading extends _component.default {
    static shouldRender(args1, context1) {
      const router1 = (0, _owner.getOwner)(context1).lookup("service:router");
      const discovery1 = (0, _owner.getOwner)(context1).lookup("service:discovery");
      return discovery1.tag?.id && router1.currentRouteName.match(/^tags?\..+/) && !router1.currentRouteName.includes("loading");
    }
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "discoveryListController", [(0, _controller.inject)("discovery.list")]))();
    #discoveryListController = (() => (dt7948.i(this, "discoveryListController"), void 0))();
    addHtmlClass() {
      const html1 = document.querySelector("html");
      html1.classList.add("show-custom-tag");
      (0, _scrollTop.scrollTop)();
    }
    removeHtmlClass() {
      const html1 = document.querySelector("html");
      html1.classList.remove("show-custom-tag");
    }
    toggleShowInfo() {
      this.discoveryListController.send("toggleTagInfo");
    }
    static #_5 = (() => dt7948.n(this.prototype, "toggleShowInfo", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable modifier-name-case }}
        <div
          class="custom-tag-wrapper"
          {{didInsert this.addHtmlClass}}
          {{willDestroy this.removeHtmlClass}}
        >
          <h1>
            <span class="tag-name">
              {{concat (i18n "agc.custom-tag.headline") " " this.discovery.tag.id}}
            </span>
            {{#if this.currentUser.admin}}
              <DButton
                class="btn-default agc-show-tag-info"
                @action={{this.toggleShowInfo}}
                @icon="tag"
              />
            {{/if}}
          </h1>
        </div>
      
    */
    {
      "id": "d0fEC6NH",
      "block": "[[[1,\"\\n\"],[1,\"    \"],[11,0],[24,0,\"custom-tag-wrapper\"],[4,[32,0],[[30,0,[\"addHtmlClass\"]]],null],[4,[32,1],[[30,0,[\"removeHtmlClass\"]]],null],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"tag-name\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[[28,[32,3],[\"agc.custom-tag.headline\"],null],\" \",[30,0,[\"discovery\",\"tag\",\"id\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"          \"],[8,[32,4],[[24,0,\"btn-default agc-show-tag-info\"]],[[\"@action\",\"@icon\"],[[30,0,[\"toggleShowInfo\"]],\"tag\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/custom-tag-heading.js",
      "scope": () => [_didInsert.default, _willDestroy.default, _helper.concat, _i18n.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AgcCustomTagHeading;
});